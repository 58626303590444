<template>
  <div class="ScreeningsUpdateCategory">
    <CloseButton />

    <!-- Top -->
    <div class="Top">
      <div class="TitleWrap">
        <div class="Icon">
          <CategoryIcon :categoryID="currentCategory.id" />
        </div>
        <div class="TextWrap">
          <span class="Title">{{ currentCategoryTitle }}</span>
          <span
            v-if="currentScreeningSelectedFilterUnitID"
            class="UnitFilterText">{{ mixWB('YOU_ONLY_SEE_FOR_ONE_UNIT') }}</span>
        </div>
      </div>
    </div>

    <div class="SplitLine"/>

    <!-- Empty list -->
    <div
      class="EmptyList"
      v-if="!connectedTypes.length && !addedTypesForCategory.length">
      <span>{{ mixWB('NOTHING_ADDED_YET') }}</span>
    </div>

    <!-- Has items -->
    <template v-else>
      <CategoryConnectedTypeList
        v-if="connectedTypes.length"
        :connectedTypes="connectedTypes" />

      <SectionHeadline
        v-if="connectedTypes.length"
        :text="mixWB('TYPES_FROM_THIS_CATEGORY')"
        hideBorder />

      <CategoryTypeList :categoryID="currentCategory.id" />
    </template>

    <router-link
      :to="{ name: 'ScreeningsUpdateCategoryAddType' }"
      class="AddTypeButtonWrap">
      <div class="AddTypeButton">
        <div class="IconWrap">
          <PlusIcon />
        </div>
        <span>{{ mixWB('ADD_TYPE') }}</span>
      </div>
    </router-link>

    <div class="SplitLine"/>

    <!-- Bottom -->
    <div class="Bottom">
      <!-- Next type button -->
      <div class="ButtonWrap">
        <Button
          :text="continueButtonText"
          @button-click="onNextClick" />
      </div>
    </div>

  </div>
</template>

<script>
import CloseButton from '@/components/ScreeningItems/CloseButton.vue'
import CategoryIcon from '@/components/Icons/CategoryIcon.vue'
import CategoryTypeList from '@/components/ScreeningItems/CategoryTypeList.vue'
import Button from '@/components/Buttons/Button.vue'
import { mapGetters } from 'vuex'
import { orderBy } from 'lodash-es'
import PlusIcon from '@/assets/svg/plus.svg?inline'
import CategoryConnectedTypeList from '@/components/ScreeningItems/CategoryConnectedTypeList.vue'
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'

export default {
  name: 'ScreeningsUpdateCategory',
  computed: {
    ...mapGetters([
      'categories',
      'categoriesToSections',
      'screeningTypes',
      'currentScreening',
      'currentScreeningSelectedFilterUnitID',
      'currentCategory',
      'currentCategoryTitle',
      'addedTypesForCategory',
    ]),
    connectedTypes() {
      if (!this.screeningTypes || !this.screeningTypes.length) {
        return []
      }
      if (!this.currentCategory || !this.currentCategory.connection) {
        return []
      }

      let types = []
      if (this.currentScreeningSelectedFilterUnitID) {
        types = this.screeningTypes.filter(
          (x) => this.currentCategory.connection.categoryIDs.includes(x.categoryID)
        && this.currentCategory.connection.categoryTypeIDs.includes(x.type.typeID)
        && x.unitIDs.includes(this.currentScreeningSelectedFilterUnitID),
        )
      }
      else {
        types = this.screeningTypes.filter(
          (x) => this.currentCategory.connection.categoryIDs.includes(x.categoryID)
        && this.currentCategory.connection.categoryTypeIDs.includes(x.type.typeID),
        )
      }

      return orderBy(types, ['categoryID', 'createdAt.seconds'], ['asc', 'asc'])
    },
    nextCategory() {
      const allCategories = this.categoriesToSections.outside
        .concat(this.categoriesToSections.inside)
        .concat(this.categoriesToSections.other)

      const currentCategoryIndex = allCategories.findIndex((x) => x.id === this.currentCategory.id)

      if (currentCategoryIndex === allCategories.length - 1) {
        return false
      }

      const nextCategory = allCategories[currentCategoryIndex + 1]

      return {
        title: this.mixWB(nextCategory.wordbook),
        path: {
          name: 'ScreeningsUpdateCategory',
          params: {
            screeningID: this.currentScreening.id,
            categoryID: nextCategory.id,
          },
        },
      }
    },
    continueButtonText() {
      if (
        (!this.addedTypesForCategory.length && !this.connectedTypes.length)
         && this.nextCategory.path
      ) {
        return `${ this.mixWB('SKIP') } ${ this.currentCategoryTitle }`
      }

      if (
        (this.addedTypesForCategory.length || this.connectedTypes.length)
        && this.nextCategory.path
      ) {
        return `${ this.mixWB('NEXT') } - ${ this.nextCategory.title }`
      }

      return this.mixWB('DONE')
    },
  },
  methods: {
    onNextClick() {
      if (!this.addedTypesForCategory.length) {
        this.$store.dispatch('setSkippedCategory', this.$route.params.categoryID)
      }

      if (!this.nextCategory.path) {
        this.$router.push({ name: 'ScreeningsUpdate', params: { screeningID: this.currentScreening.id } })
        return
      }

      this.$router.push(this.nextCategory.path)
    },
  },
  components: {
    CloseButton,
    CategoryIcon,
    Button,
    CategoryTypeList,
    PlusIcon,
    CategoryConnectedTypeList,
    SectionHeadline,
  },

}
</script>

<style lang="stylus" scoped>
  .ScreeningsUpdateCategory
    pageWrap()

  .Top
    margin-top -7px
    .TitleWrap
      display flex
      align-items center
    .Icon
      display flex
      align-items center
      circle(40px)
      padding 8px
      border 1px solid $color_primary
      margin-right 10px
      svg
        box(100%)
        fill $color_grey
    .TextWrap
      .Title
        display block
        font-size 1.125rem
        font-weight bold
        text-transform uppercase
      .UnitFilterText
        display block
        color $color_salmon

  .SplitLine
    margin 15px -20px
    border-bottom 1px solid $color_grey_lighter

  .EmptyList
    margin-bottom 20px
    span
      font-style italic
      color $color_grey

  .AddTypeButtonWrap
    display block

  .AddTypeButton
    display flex
    align-items center
    background-color $color_grey_lightest
    padding 5px
    box-shadow $box_shadow_1
    .IconWrap
      box(90px)
      padding 20px
      margin-right 10px
      background-color $color_primary
      svg
        fill #fff
    span
      font-size 1.125rem
      text-transform uppercase
      display block

  .ButtonWrap
    display block
    margin-bottom 10px

</style>
